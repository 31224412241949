<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-left">
                        <v-btn text :to="{name: 'admin.apartments.index'}">return</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        {{ apartment.id }}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="apartment.project_id"
                                :items="projects"
                                item-text="name"
                                item-value="id"
                                outlined
                                label="Select a project"
                            >
                            </v-select>
                            <v-text-field type="number" min="0" :rules="notEmpty" label="Project id"
                                          v-model="apartment.project_id"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" label="Bedrooms" :rules="notEmpty" placeholder="Please enter a bedrooms number"
                                          outlined v-model="apartment.bedrooms"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" placeholder="Please enter a floor" :rules="notEmpty"
                                          label="Floor"
                                          v-model="apartment.floor"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  type="number" placeholder="Please enter an area" :rules="notEmpty"
                                           label="Area"
                                           v-model="apartment.area"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field type="number" placeholder="Please enter a price" :rules="notEmpty"
                                          label="Price"
                                          v-model="apartment.price"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="apartment.created_at">
                        <v-col>
                            <v-text-field label="Created at" readonly disabled v-model="formatDate"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveApartment">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Apartment from "../../../models/Apartment";
import Project from "../../../models/Project";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";

export default {
    name: "apartments-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            formatDate: 0,
            flagSaveUpdate: false,
            apartment: {},
            projects: [],
            valid: false,
            notEmpty: [v => !!v || 'This field must not be empty'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    methods: {
        async handleSaveApartment() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const apartment = await new Apartment(this.apartment)

                this.apartment = this.flagSaveUpdate
                    ? await apartment.patch()
                    : await apartment.save()

                await this.$router.push({name: 'admin.apartments.show', params: {id: this.apartment.id}})

                this.snackbar = true
            }
        },
        returnFormDate(uc) {
            this.formatDate = new Date(uc).toLocaleString()
        }
    },

    async mounted() {
        const apartmentId = this.$route.params.id
        if (apartmentId) {
            this.apartment = await Apartment.find(apartmentId)
            this.flagSaveUpdate = true
            this.returnFormDate(this.apartment.created_at)
        }
        this.projects = await Project.get()
    },
}
</script>

<style lang="scss" scoped>
.min-width100 {
    min-width: 100%;
}

.max-width250 {
    max-width: 250px;
}
</style>
